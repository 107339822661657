import { gsap } from 'gsap'
import { RoughEase } from 'gsap/EasePack'
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin, RoughEase)

const words = [
  'Ishak Antony Darmawan.',
  'A Software Engineer.',
  'A DevOps Engineer.',
  'A Technical Lead.',
]

let cursor = gsap.to('.cursor', {
  opacity: 0,
  ease: 'power2.inOut',
  repeat: -1,
})

let boxTl = gsap.timeline()

boxTl
  .to('.box', {
    duration: 1,
    width: '34vw',
    delay: 0.5,
    ease: 'power4.inOut',
  })
  .from('.greeting', {
    duration: 1,
    y: '17vw',
    ease: 'power3.out',
    onComplete: () => textTl.play(),
  })
  .to('.box', { duration: 1, height: '12vw', ease: 'elastic.out' })

let textTl = gsap.timeline({ repeat: -1 }).pause()

words.forEach((word) => {
  let tl = gsap.timeline({
    repeat: 1,
    yoyo: true,
    repeatDelay: 1,
  })

  tl.to('.text', {
    duration: 1,
    text: word,
  })
  textTl.add(tl)
})
