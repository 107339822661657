/**
 * Import the app.scss only, if you want to add more css/scss always use the app.scss to import
 */
import "./scss/app.scss";

/**
 * Use modules pattern in your javascript, don't put all your javascript in this main.js
 * See example below
 */

import "./app.js";
